<i18n>
{
  "de": {
    "heatingHeading": "Heizungsersatz",
    "nameTitle": "Energieträger",
    "initialTitle": "Initialkosten",
    "perKwTitle": "Leistungsabhängig"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    class="c-edit-investment-costs"
    :allow-edit="getPortfolioPermission('EDIT_INVESTMENT_COSTS')"
    @container="onFormEvent"
  >
    <h2>{{ $t('heatingHeading') }}</h2>
    <DetailList has-header class="heating-costs-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span>{{ $t('initialTitle') }}</span>
        <span>{{ $t('perKwTitle') }}</span>
      </template>
      <li v-for="(heating, heatingKey) in model" :key="heatingKey">
        <div>
          {{ $t(`_heatingTypes.${heatingKey}`) }}
        </div>
        <div>
          <NumericInput
            v-model="heating.initial"
            :edit="isEditing"
            optional
            :units="'CHF'"
            @validation="onValidation($event, 'costs-initial')"
          />
        </div>
        <div>
          <NumericInput
            v-model="heating.per_kw"
            :edit="isEditing"
            optional
            :units="'CHF/kW'"
            @validation="onValidation($event, 'costs-per-kw')"
          />
        </div>
      </li>
    </DetailList>
  </EditContainer>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    DetailList,
    NumericInput,
    EditContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.resetModel()
  },

  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams',
    }),

    resetModel() {
      // Deep copy
      this.model = JSON.parse(JSON.stringify(this.portfolio.investment_costs.heating))
    },

    async saveHandler() {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          investment_costs: {
            heating: this.model,
          },
        },
      })
    },
  },
}
</script>
